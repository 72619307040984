
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.diff-form-versions-modal {
  &__item-value {
    margin-bottom: $base-gutter;
  }
}
