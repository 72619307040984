@use "sass:math";
$workflow-offset: 10px;
$workflow-offset-half: math.div($workflow-offset, 2);
$workflow-btn: 48px;
$workflow-btn-half: math.div($workflow-btn, 2);
$workflow-btn-third: math.div($workflow-btn, 3);

.form-workflow {
  $currentClass: &;

  width: 100%;
  padding-bottom: 80px;

  &__block {
    background-color: $color-white;
    border-radius: $base-border-radius;
    padding: $workflow-offset * 2;
    & + & {
      margin-top: $workflow-offset * 2;
    }
    &--gray {
      background-color: $color-gray-status-op-15;
    }
    &--collapser {
      border: none;
      ::v-deep > .esmp-collapser {
        &__heading {
          flex-direction: row-reverse;
          .esmp-collapser__title {
            margin: 0 10px 0 0;
          }
        }
        &__body {
          padding-left: 0;
        }

      }
    }
    &--small {
      padding: $workflow-offset;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    & + & {
      margin-top: $workflow-offset;
    }
    &--right {
      justify-content: flex-end;
    }
    &--big-offset,
    & + &--big-offset {
      margin-top: $workflow-offset * 2;
    }
  }
  &__col {
    & + & {
      margin-left: $workflow-offset;
    }

    &--full-width {
      width: 100%;
    }

    &--half-width {
      width: calc(50% - $workflow-offset-half);
    }

    &--multi-dependency {
      width: calc(50% - $workflow-btn-half - $workflow-offset);
      &:nth-child(3) {
        width: $workflow-btn;
      }
      &:only-child {
        margin-left: calc(50% - $workflow-offset-half);
      }
    }

    &--many-dependency {
      width: calc(33% - $workflow-btn-third - $workflow-offset);
      &:last-child {
        width: $workflow-btn;
      }
    }
  }

  &__condition {
    margin-top: $workflow-offset * 2;
  }

  &__errors {
    margin-top: $workflow-offset;
    color: $color-red;
    font-size: $font-size-small;
  }

  &__duty-queue {
    margin-top: $workflow-offset;
  }

  &__multi-dependency {
    display: grid;
    gap: $workflow-offset;
    grid-template-columns: 1fr 1fr $workflow-btn;
    grid-template-areas: "control value btn"
                         ". condition .";

    &__cell {
      &:nth-child(4) {
        grid-area: condition;
      }
    }
  }
}

