
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

@import '~@/assets/scss/blocks/form-workflow.scss';
.form-dependency {
  margin: 15px 0;
  padding: 15px 0 30px;
  border-bottom: 1px solid;
}
.form-dependencies__empty-text {
  margin-top: -20px;
}
