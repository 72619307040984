
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.subscriptions {
  &__list-item {
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
    border-bottom: 1px solid $color-gray-status-op-15;
    word-break: break-word;
  }

  &__list-item-actions {
    display: flex;
    flex-shrink: 0;

    .esmp-svg-icon {
      width: 18px;
      height: 18px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
}
.subscriptions-modal {
  .user-search {
    &__email {
      font-size: 12px;
      line-height: 16px;
      color: $color-gray-status-op-60;
    }
  }

  .esmp-select-disabled {
    opacity: .5;
    .esmp-select-head {
      background-color: $color-grayscale-40;
    }
  }
}
