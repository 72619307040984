
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.forms-table {
  &__actions-item {
    color: $color-black;
  }

  &__action-buttons {
    display: flex;
    gap: $gap / 2;
    justify-content: flex-end;

    .esmp-button-group {
      .esmp-button {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
  .esmp-table {
    &__thead {
      display: none;
    }
    &__tbody-td {
      &:first-child {
        width: 24px;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(7) {
        width: 150px;
      }

      &:nth-child(6) {
        width: 100px;
      }
    }
  }
}
