
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.page__edit-form {
  padding-bottom: 80px;
}
table tr td:first-child {
  width: 350px;
}
table tr td {
  padding: 10px 10px 10px 0;
  &:first-child {
    text-align: right;
    color: $color-grayscale-60;
  }
}
.esmp-input ::v-deep textarea.esmp-input-element {
  height: 100px;
}

.edit-form {
  &__right-bar {
    display: flex;
    align-items: center;
  }
  &__expander {
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 200px;
    &:hover {
      cursor: pointer;
    }

    &-text {
      display: inline-block;
      margin-bottom: -4px;
    }
  }
  &__search-field  ::v-deep .esmp-input-element {
    background-color: $color-white;
  }
}

.setting-form-table-wrapper {
  padding: 20px;
  background: #fff;
  border-radius: 20px;
}

.setting-form-table {
  &__notice {
    &-title {
      color: $color-red;
    }
    &-text {
      font-size: 12px;
    }
  }
  &__comment {
    width: 400px;
  }
}
